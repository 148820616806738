import cookie from "react-cookies";

export const getCookie = (cookie_name) => {
    return cookie.load(cookie_name);
};

export const setCookie = (cookie_name, value, age = 5184000) => {
    cookie.save(cookie_name, value, {
        maxAge: age,
        path: "/",
        domain: ".myyogateacher.com",
        secure: true,
        SameSite: "Strict",
    });
};

export const removeCookie = (cookie_name) => {
    cookie.remove(cookie_name, { path: "/", domain: ".myyogateacher.com" });
    cookie.remove("gc_monetization_tag", {
        path: "/",
        domain: ".myyogateacher.com",
    });
    cookie.remove("gc_monetization_url", {
        path: "/",
        domain: ".myyogateacher.com",
    });
};