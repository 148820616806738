import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AppContainer from './app_container';
import LoadingScreen from '../components/util_components/loading_screen';
// import RoutesWithRedux from './routes_with_redux';
const RoutesWithRedux = lazy(() => import('./routes_with_redux'));
const LandingPage = lazy(() => import('../components/landing_pages/LandingPage'));
const LandingPage2 = lazy(() => import('../components/landing_pages/LandingPage2'));
const LandingPage4 = lazy(() => import('../components/landing_pages/LandingPage4'));
const LandingPagej1 = lazy(() => import('../components/landing_pages/LandingPageJ1'));
const LandingPagej2 = lazy(() => import('../components/landing_pages/LandingPageJ2'));
const LandingPageS3 = lazy(() => import('../components/landing_pages/LandingPageS3'));
const LandingPageS4 = lazy(() => import('../components/landing_pages/LandingPageS4'));
const LandingPagej3 = lazy(() => import('../components/landing_pages/LandingPageJ3'));
const LandingPagej4 = lazy(() => import('../components/landing_pages/LandingPageJ4'));
const LandingPageV1 = lazy(() => import('../components/landing_pages/LandingPageV1'));
const LandingPageV3 = lazy(() => import('../components/landing_pages/LandingPageV3'));
const LandingPageV2 = lazy(() => import('../components/landing_pages/LandingPageV2'));
const LandingPageV4 = lazy(() => import('../components/landing_pages/LandingPageV4'));
const GroupLandingPageV1 = lazy(() => import('../components/landing_pages/GroupLandingV1'));
const FGCLandingPage = lazy(() => import('../components/landing_pages/FGCLandingPage'));
const FYC30dLandingPage = lazy(() => import('../components/landing_pages/FYC30dLandingPage'));
// const LandingPageV4Small = lazy(() => import('../components/landing_pages/LandingPageV4Small'));
const FiveFree = lazy(() => import('../components/landing_pages/five_free'));
const FiveFreeThankYou = lazy(() => import('../components/landing_pages/five_free_thank_you'));
const ProductHunt = lazy(() => import('../components/landing_pages/product_hunt'));
const ProductHuntThankYou = lazy(() => import('../components/landing_pages/product_hunt_thank_u'));
const LandingPageV5 = lazy(() => import('../components/landing_pages/LandingPageV5'));
const LandingPageV6 = lazy(() => import('../components/landing_pages/LandingPageV6'));
const LandingPageV7 = lazy(() => import('../components/landing_pages/LandingPageV7'));
const LandingPage3 = lazy(() => import('../components/landing_pages/LandingPage3'));
const SampleTest = lazy(() => import('../components/landing_pages/sample_test'));
const ThankYou = lazy(() => import('../components/landing_pages/thank_you'));
const ThankYou1 = lazy(() => import('../components/landing_pages/thank_you_1'));
const ThankYouFull = lazy(() => import('../components/landing_pages/thank_you_full'));
const Explainer = lazy(() => import('../components/other/explainer_video'));
const BetaProgram = lazy(() => import('../components/other/beta_program'));
const BetaProgramWname = lazy(() => import('../components/other/beta_program_wname'));
const GiftingError = lazy(() => import('../components/other/gifting_error'));
const UndefinedComp = lazy(() => import('../components/landing_pages/undefined'));
const BlogPage = lazy(() => import('../components/other/blog'));
const BlogDetailPage = lazy(() => import('../components/other/blog/blog_post'));

const NewHome = lazy(() => import('../components/home'));
const FullVideoUrl = lazy(() => import('../components/other/full_url_video'));
const AllArticles = lazy(() => import('../components/other/blog/allarticles'));

const RoutesWithReduxNew = lazy(() => import('../version1/components/componentWrapper'));
const BanyanHome = lazy(() => import('../version1/modules/home/index'));
const landing_pages = () => {
	return (
		<BrowserRouter>
			<Suspense fallback={<LoadingScreen />}>
				<AppContainer>
					<Switch>
						{/* <Route path='' component={(props) => <RoutesWithReduxNew {...props} />} /> */}
						<Route exact path='/' component={(props) => <BanyanHome {...props} isHeader={true} isFooter={true} />} />
						<Route exact path='/sample_test' component={(props) => <SampleTest {...props} />} />
						<Route exact path='/launch_campaign2' component={(props) => <LandingPage2 {...props} />} />
						<Route exact path='/launch_campaign/s1' component={(props) => <LandingPage3 {...props} />} />
						<Route exact path='/launch_campaign/s2' component={(props) => <LandingPage4 {...props} />} />
						<Route exact path='/launch_campaign/j1' component={(props) => <LandingPagej1 {...props} />} />
						<Route exact path='/launch_campaign/j2' component={(props) => <LandingPagej2 {...props} />} />
						<Route exact path='/launch_campaign/s3' component={(props) => <LandingPageS3 {...props} />} />
						<Route exact path='/launch_campaign/s4' component={(props) => <LandingPageS4 {...props} />} />
						<Route exact path='/launch_campaign/j3' component={(props) => <LandingPagej3 {...props} />} />
						<Route exact path='/launch_campaign/j4' component={(props) => <LandingPagej4 {...props} />} />
						<Route exact path='/launch_campaign/v3' component={(props) => <LandingPageV3 {...props} />} />
						<Route exact path='/launch_campaign/v2' component={(props) => <LandingPageV2 {...props} />} />
						<Route exact path='/launch_campaign/v1' component={(props) => <LandingPageV1 {...props} />} />
						<Route exact path='/launch_campaign/v4' component={(props) => <LandingPageV4 {...props} />} />
						<Route exact path='/launch_campaign/v5' component={(props) => <LandingPageV5 {...props} />} />
						<Route exact path='/launch_campaign/v6' component={(props) => <LandingPageV6 {...props} />} />
						<Route exact path='/launch_campaign/v7' component={(props) => <LandingPageV7 {...props} />} />
						<Route exact path='/launch_campaign1' component={(props) => <LandingPage {...props} />} />
						<Route exact path='/free-beta-test' component={(props) => <LandingPageV4 {...props} />} />
						<Route exact path='/5-free-sessions' component={(props) => <LandingPageV4 {...props} />} />
						<Route exact path='/5-free-sessions-v1' component={(props) => <FiveFree {...props} />} />
						<Route exact path='/5-free-sessions-v1i' component={(props) => <FiveFree {...props} />} />
						<Route exact path='/5-free-sessions-v1a' component={(props) => <FiveFree {...props} />} />
						<Route exact path='/back-door-access' component={(props) => <LandingPageV4 {...props} />} />
						<Route exact path='/5-free-sessions-v1/thank-you' component={(props) => <FiveFreeThankYou {...props} />} />
						<Route exact path='/5-free-sessions-v1i/thank-you' component={(props) => <FiveFreeThankYou {...props} />} />
						<Route exact path='/5-free-sessions-v1a/thank-you' component={(props) => <FiveFreeThankYou {...props} />} />
						<Route exact path='/back-door-access' component={(props) => <LandingPageV4 {...props} />} />
						<Route path='/referral' component={(props) => <LandingPageV4 {...props} />} />
						<Route path='/gc/referral' component={(props) => <GroupLandingPageV1 {...props} />} />
						<Route path='/fyc/referral' component={(props) => <FGCLandingPage {...props} />} />
						<Route path='/fyc30d/referral' component={(props) => <FYC30dLandingPage {...props} />} />
						<Route exact path='/thankyou' component={(props) => <ThankYou {...props} />} />
						<Route exact path='/5-free-sessions/thank-you' component={(props) => <ThankYou {...props} />} />
						<Route exact path='/producthunt' component={(props) => <ProductHunt {...props} />} />
						<Route exact path='/producthunt/thank-you' component={(props) => <ProductHuntThankYou {...props} />} />
						<Route exact path='/thank-you-1' component={(props) => <ThankYou1 {...props} />} />
						<Route exact path='/test-program-full' component={(props) => <ThankYouFull {...props} />} />
						<Route exact path='/explainer' component={(props) => <Explainer {...props} />} />
						<Route exact path='/beta-test-aug-19' component={(props) => <BetaProgram {...props} />} />
						<Route exact path='/beta-test' component={(props) => <BetaProgramWname {...props} />} />
						<Route exact path='/undefined' component={(props) => <UndefinedComp {...props} />} />
						<Route exact path='/gifting' component={(props) => <GiftingError {...props} />} />
						<Route exact path='/valentines-day-special' component={(props) => <GiftingError {...props} />} />
						{/* <Route exact path='/' component={(props) => <NewHome {...props} />} /> */}
						<Route exact path='/free-yoga-classes-ga-v01/' component={(props) => <NewHome {...props} />} />
						<Route exact path='/full_video' component={(props) => <FullVideoUrl {...props} />} />
						<Route exact path='/articles' component={(props) => <BlogPage {...props} />} />
						<Route exact path='/articles/search' component={(props) => <AllArticles {...props} />} />
						<Route exact path='/articles/:slug' component={(props) => <BlogDetailPage {...props} />} />

						<Route path='*' component={(props) => <RoutesWithRedux {...props} />} />
					</Switch>
				</AppContainer>
			</Suspense>
		</BrowserRouter>
	);
};

export default landing_pages;
