import React from "react";
import "./loading_screen.css";
const LoadingScreen = props => (
  <div className="custom-loading-screen">
    {/* {typeof props.show_logo === "undefined" || props.show_logo ? (
      <img className="myt-logo" src="https://images.myyogateacher.com/myt-logo.png" alt="Logo" />
    ) : null} */}
    <div className="custom-loading-animation" />
  </div>
);

export default React.memo(LoadingScreen);
