import { Component, lazy } from 'react';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

class AppContainer extends Component {
	loaded_mixpanel = false;
	componentDidMount() {
       
		ReactGA.initialize('UA-125736216-1');
		this.listen_to_new_route();
	}
	componentDidUpdate() {
		this.listen_to_new_route();
	}
	load_mixpanel = () => {
		const mixpanel = require('mixpanel-browser');
		mixpanel.init('ef1b4a4e8c1cd36eb950d3ab05e70a57', {
			loaded: function(mixpanel) {
				console.log('mixpanel-distinct 2', mixpanel.get_distinct_id());
			}
		});
	};
	load_stripe = () => {
		try {
			const script = document.createElement('script');
			script.src = 'https://js.stripe.com/v3/'; // URL for the third-party library being loaded.
			document.body.appendChild(script);
		} catch {}
	};
	listen_to_new_route = () => {
		if (pathname !== '/' && !this.loaded_mixpanel) {
			this.load_mixpanel();
		}
		const urlString = window.location.href;
		const url = new URL(urlString);
		const pathname = url.pathname;
		if (pathname !== '/' && pathname !== '/blog' && !window.Stripe) {
			this.load_stripe();
		}
		let final_pathname = '';
		switch (pathname) {
			case '/launch_campaign/v2':
				final_pathname = '/campaign_v2';
				break;
			case '/launch_campaign/v3':
				final_pathname = '/campaign_v3';
				break;
			case '/launch_campaign/v4':
				final_pathname = '/campaign_v4';
				break;
			case '/launch_campaign1':
				final_pathname = '/campaign';
				break;
			case '/launch_campaign2':
				final_pathname = '/campaign2';
				break;
			case '/launch_campaign/s1':
				final_pathname = '/ccampaign_s1';
				break;
			case '/launch_campaign/s2':
				final_pathname = '/campaign_s2';
				break;
			case '/launch_campaign/j1':
				final_pathname = '/campaign_j1';
				break;
			case '/launch_campaign/j2':
				final_pathname = '/campaign_j2';
				break;
			case '/launch_campaign/j3':
				final_pathname = '/campaign_j3';
				break;
			case '/launch_campaign/j4':
				final_pathname = '/campaign_j4';
				break;
			case '/launch_campaign/s3':
				final_pathname = '/campaign_s3';
				break;
			case '/launch_campaign/s4':
				final_pathname = '/campaign_s4';
				break;
			default:
				final_pathname = pathname;
				break;
		}
		if (process.env.REACT_APP_STAGE === 'production') {
			ReactGA.pageview(final_pathname);
		}
	};
	render() {
		return this.props.children;
	}
}

export default withRouter(AppContainer);
